import React from 'react';
import { Link, graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
    IntroQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const Andachtsversammlungen = ({ data }) => (
    <InnerPage
        title="Andachtsversammlungen"
        description="Gemeinsam über das Wort Gottes sprechen und nachdenken"
    >
        <IntroHolder
            title="Andachtsversammlungen"
            subtitle="Gemeinsam über das Wort Gottes sprechen und nachdenken"
            picture={data.introImage}
            breadcrumbs={{
                grandparent: 'Gemeinsames Handeln',
                parent: 'Andacht und Dienst',
                'current-item': 'Andachtsversammlungen',
            }}
        >
            <IntroQuote>
                Die Gebetshaltung ist der beste Zustand; denn in diesem Zustand
                hält der Mensch Zwiesprache mit Gott. [...] Wahrlich, das Gebet
                verleiht Leben.
            </IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    Das Gebet und das Lesen von Heiligen Schriften gehören zum
                    Alltag vieler religiöser Menschen. Im persönlichen Gespräch
                    mit Gott wird Dank ausgedrückt oder um Beistand und Führung
                    gebeten.
                </p>
                <div>
                    <p>
                        Daneben inspiriert das Wort Gottes den Menschen zum{' '}
                        <Link to="/gemeinsames-handeln/">Handeln </Link>und
                        dazu, einen Beitrag zum Wohlergehen der Gesellschaft zu
                        leisten. Diese zwei Elemente sind untrennbar miteinander
                        verbunden und führen zum Wachstum des Gemeindelebens.
                    </p>
                    <p>
                        Bahá’í beten gemeinsam mit ihren Freunden, Bekannten und
                        Nachbarn – unabhängig von ihrem religiösen Hintergrund –
                        in Gemeindezentren oder privaten Wohnstätten. Bei diesen
                        Andachtsversammlungen werden Texte aus den Heiligen
                        Schriften der Bahá’í-Religion und anderer Religionen
                        gelesen und rezitiert, oft gehört auch Musik zur
                        Andacht. Es gibt keine bestimmten Rituale oder
                        Konventionen, wie der Raum für eine Andacht gestaltet
                        werden soll. Jedem Gastgeber steht es frei, eine
                        würdevolle Atmosphäre nach eigenem Belieben zu schaffen.
                    </p>
                    <p>
                        <img
                            alt=""
                            src={data.collage.childImageSharp.fluid.src}
                            className="aligncenter"
                        />
                    </p>
                    <p>
                        Das gemeinsame Beten und Zusammensein bei einer Andacht
                        verbindet Menschen auf eine geistige Art und Weise. Die
                        aus den heiligen Texten gewonnene Inspiration kann im
                        Handeln ihren Ausdruck finden – sei es als Dienst am
                        Nächsten, oder als gemeinschaftliches Handeln für das
                        Wohl der Gesellschaft.
                    </p>
                </div>
                <Reference>
                    <p>1. ‘Abdu’l-Bahá, Über die Macht des Gebetes, S. 14</p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default Andachtsversammlungen;

export const pageQuery = graphql`
    query {
        introImage: file(
            relativePath: { eq: "andachtsversammlungen-feature.jpg" }
        ) {
            ...fluidImage
        }
        collage: file(relativePath: { eq: "andachtsversammlungen-img-1.jpg" }) {
            ...fluidImage
        }
    }
`;
